export const formatDate = (inpDate) => {
    let convertedDate = new Date(inpDate);
    let month = convertedDate.getMonth() + 1;
    let day = convertedDate.getDate();
    let year = convertedDate.getFullYear();
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }
    let shortDate = `${day}-${month}-${year} 00:00:00`;

    return shortDate;
};

export const formatDateTime = (inpDate) => {
    let convertedDate = new Date(inpDate);
    let month = convertedDate.getMonth() + 1;
    let day = convertedDate.getDate();
    let year = convertedDate.getFullYear();
    let hour = convertedDate.getHours();
    let minute = convertedDate.getMinutes();
    let seconds = convertedDate.getSeconds();
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }
    let shortDate = `${day}-${month}-${year} ${hour}:${minute}:${seconds}`;

    return shortDate;
};

export const statusOptions = [
    { name: 'Active', code: 'Active' },
    { name: 'Inactive', code: 'Inactive' },
    { name: 'Lock', code: 'Lock' },
];

export const userRolesOptions  = [
    { name: 'System Admin', code: 'SystemAdmin' },
    { name: 'System User', code: 'SystemUser' },
];

export const fileGroupOptions = [
    { name: 'FGPT', code: 'FGPT' },
    { name: 'FGPM', code: 'FGPM' },
    { name: 'MCT', code: 'MCT' },
    { name: 'EIT', code: 'EIT' },
    { name: 'MCT', code: 'MCT' },
    { name: 'ITT', code: 'ITT' },
    { name: 'RECT', code: 'RECT' },
    { name: 'ILT', code: 'ILT' },
    { name: 'IPRT', code: 'IPRT' },
    { name: 'CPNT', code: 'CPNT' },
    { name: 'PJO', code: 'PJO' },
    { name: 'CBT', code: 'CBT' },
];
export const fileTypeOptions = [
    { name: 'Inbound', code: 'inbound' },
    { name: 'Outbound', code: 'outbound' },
]

export const yesNoOptions = [
    { name: 'Yes', code: 'Y' },
    { name: 'No', code: 'N' },
]

export const fileStatusOptions = [
    { name: 'Active', code: 'Active' },
    { name: 'Inactive', code: 'Inactive' },
    { name: 'Error', code: 'error' },
    { name: 'Pending', code: 'pending' },
];